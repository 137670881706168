<template>
  <v-card elevation="0" :loading="loading">
    <v-card-title>Disponibilitées</v-card-title>
    <v-card-text
        max-width="600"
        class="mx-auto"
    >
      <v-list
          subheader
          two-line
      >
        <v-list-item
            v-for="disponibility in disponibilities.listEntity"
            :key="disponibility.id"
        >
          <v-list-item-content>
            <v-list-item-subtitle>Nom : {{ disponibility.affiliate.user.firstname }} - {{ disponibility.affiliate.user.lastname }}</v-list-item-subtitle>
            <v-list-item-subtitle>Départ: {{ disponibility.departure }} : {{ disponibility.departuretime }}</v-list-item-subtitle>
            <v-list-item-subtitle>Arrivée: {{ disponibility.departure }} : {{ disponibility.timetoarrive }}</v-list-item-subtitle>

            <v-list-item-subtitle>Nbre colis: {{ disponibility.numberofpackages }}; maxweight : {{ disponibility.maxweight }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-card-text>
    <v-card-text class="pt-2">
      <v-row>
        <v-col
            lg="2"
            cols="3"
        >

          <v-select
              v-model="disponibilities.per_page"
              label="Lignes par page:"
              :items="[10,20,30,50,80,100, 'Toutes']"
              hide-details
              @change="fetchItems()"
          ></v-select>

        </v-col>

        <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
        >
          <v-pagination
              v-model="disponibilities.current_page"
              total-visible="6"
              :length="parseInt(disponibilities.pagination )"
              @input="fetchItems()"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {Drequest} from "../plugins/Drequest";
export default {
  props: ["disponibilities"],
  beforeMount() {
    this.loadDisponibilities();
  },
  methods: {
    loadDisponibilities(){
      Drequest.api("lazyloading.disponibility?dfilters=on")
          .get((response) => {
            this.disponibilities = response;
            this.loading = false;
          });
    }
  },
  data: () => ({
    loading: true,
    disponibilities: [],
  }),
}
</script>
<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
</style>