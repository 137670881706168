<template>
  <div>
    <v-row>
      <v-col cols="12">
        <breadcrumbs :items="dataBreadcrumbs" />
      </v-col>
    </v-row>

    <v-row v-if="transporter.status._key == 'approved'">
      <v-col cols="12">
        <div>
          <h3>{{ $t('dashboard.title') }}</h3>
        </div>
      </v-col>
      <v-col
          cols="12"
          md="8"
      >
        <dashboard-shipment-summary-card :transporter="transporter"></dashboard-shipment-summary-card>
        <br>
        <order-list :transporter="transporter" :countries="transporter.countries"></order-list>
      </v-col>
      <v-col
          cols="12"
          md="4"
          sm="6"
      >
        <disponibility-list :disponibilities="disponibilities"></disponibility-list>
      </v-col>
    </v-row>
    <div v-else>
      <v-card elevation="0" dark color="warning" class="text-white text-center">
        <v-card-text> Merci d'être enroler en tant transporteur, votre compte est en attente d'approbation
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import DashboardShipmentSummaryCard from './DashboardShipmentSummaryCard.vue';
import OrderList from "@/views/OrderList";
import DisponibilityList from "@/components/DisponibilityList";
import breadcrumbs from "../../components/ui/breadcrumbs";
import {
  mdiBookmark
} from '@mdi/js'
export default {
  components: {
    DashboardShipmentSummaryCard,
    //DashboardPickupSummaryCard,
    OrderList,
    DisponibilityList,
    breadcrumbs
  },
  data() {
    return {
      dataBreadcrumbs: [],
      disponibilities: [],
      transporter: {},
      icons: {
        mdiBookmark
      },
    }
  },
  beforeMount() {
    this.dataBreadcrumbs = [
      {
        text: 'Accueil',
        disabled: true,
        href: '/dashboard',
      }
    ];
    this.transporter = this.$store.state.transporter;
  },
}
</script>
<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import '../../assets/css/bootstrap.min.css';
</style>