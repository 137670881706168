<template>
  <v-card elevation="0" :loading="isLoading">
    <v-card-title class="align-start mb-5">
      <span class="font-weight-semibold">{{ $t('dashboard.summary.shipment') }}</span>
      <v-spacer></v-spacer>
      <v-btn
          icon
          small
          class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            v-for="data in statisticsData"
            :key="data.status._key"
            cols="6"
            md="4"
            class="d-flex align-center"
        >
          <v-avatar
              size="44"
              :color="resolveIconVariation (data.status._key).color"
              rounded
              class="elevation-1"
          >
            <v-icon
                dark
                color="white"
                size="30"
            >
              {{ resolveIconVariation(data.status._key).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.status.label }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.nb_element }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline} from '@mdi/js'
import {Drequest} from "../../plugins/Drequest";
/*const statisticsData = [
      {
            title: 'Total Livraison',
            total: '245k',
          },
          {
            title: 'Colis Livrés',
            total: '12.5k',
          },
          {
            title: 'Colis Non Livrés',
            total: '1.54k',
          }
    ]*/

const resolveIconVariation = data => {
  if (data === 'pending' || data === 'relay-point') return {icon: mdiTrendingUp, color: 'primary'}
  if (data === 'in_process' || data === 'pending-retrieval') return {icon: mdiAccountOutline, color: 'warning'}
  if (data === 'complete' || data === 'Colis Non Livrés') return {icon: mdiLabelOutline, color: 'warning'}
  if (data === 'canceled' || data === 'damaged') return {icon: mdiLabelOutline, color: 'warning'}
  return {icon: mdiAccountOutline, color: 'success'}
}

export default {
  props:["transporter"],
  data() {
    return {
      isLoading: false,
      statisticsData: [],
      resolveIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
      },
    }
  },
  mounted() {
    var user = JSON.parse(this.$store.state.user)
    Drequest.api("dashboard?user.id:eq=" + user.id)
        .get((response) => {
          this.statisticsData = response.data;
          this.isLoading = false
        })

  }
}
</script>
